import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { authRoutes } from './AuthRoutes';
import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import PrivateRoute from './PrivateRoute';
import { cookies } from '../utils';


function isLogin(){

    const unparsedStockUser = cookies.get('iwoUser')
        
    // let userCookies =null
    let getStockUser=null

        if(unparsedStockUser){
            getStockUser =JSON.parse(cookies.get('cashUser'));
        }

        // console.log(getStockUser)
        // return localStorage.getItem("IwoCashToken") ?  true :  false
         if(localStorage.getItem("IwoCashToken")){
            return true;
        }else if (getStockUser!=null) {
            console.log(null)
            localStorage.setItem("IwoCashToken", getStockUser?.token);
            localStorage.setItem("country_name", getStockUser?.country_name)
            localStorage.setItem("company_id", getStockUser?.company_id);
            localStorage.setItem("company_name", getStockUser?.company_name);
            localStorage.setItem("company_logo", getStockUser?.company_logo);
            localStorage.setItem("username", getStockUser?.username);
            localStorage.setItem("full_name", getStockUser?.full_name);
            localStorage.setItem("entity_name", getStockUser?.entity_name);
            localStorage.setItem("infos", JSON.stringify(getStockUser));
            localStorage.setItem("entity_id", getStockUser?.entity_id);
            return true;
        }
        return false
    }

const Routers = () => {
    let auth = isLogin();
    return (
        <BrowserRouter basename={'/'}>
            <>
                <Routes>
                    <Route path={'/'} element={<PrivateRoute />}>
                        {auth === true ?
                            <>
                                <Route exact path='' element={<Navigate to='/dashboard/' />} />
                                <Route exact path={`/`} element={<Navigate to='/dashboard/' />} />
                                <Route exact path={`login`} element={<Navigate to='/dashboard/' />} />
                                <Route path={`/*`} element={<LayoutRoutes />} />
                                {authRoutes.map(({ path, Component }, i) => (
                                    <Route path={path} element={Component} key={i} />
                                ))}
                            </> : 
                            <>
                                <Route exact path='' element={<Signin />} />
                                <Route exact path={`/`} element={<Signin />} />
                                <Route exact path={`/SignIn/:IwoToken:/`} element={<Signin />} />
                                <Route exact path={`/*`} element={<Signin />} />
                            </>
                            
                        }
                    </Route>                        
                </Routes>
            </>
        </BrowserRouter>
    );
};

export default Routers;